<template>
    <div>
        <h3 style="text-align: center;">Campaigns / Meetings
            <router-link v-if="store.state.account && store.state.account.is_staff" class="ms-auto"
                style="text-decoration: none;" :to="{ name: 'newCampaign' }">
                <span class="material-icons text-success" style="cursor: pointer;">post_add</span>
            </router-link>
        </h3>
    </div>

    <hr>

    <div>
        <h3>ERPA Awareness Campaigns</h3>
        <p>If you want to know what ERPA has done and is doing to improve the dismal state of
            municipal affairs in Emfuleni, book your seat for the next meeting now!
            You need to know what you can do to alleviate your own plight, and how we can assist.
        </p>
    </div>

    <hr>

    <div id="next-campaigns">
        <h3>Upcoming Campaigns:</h3>
        <template v-if="campaigns && campaigns.length > 0">
            <div class='row'>
                <template v-for="campaign in campaigns">
                    <template v-if="campaign.active && new Date(campaign.date) > new Date()">
                        <CampaignDetail :campaign="campaign" />
                    </template>
                </template>
            </div>
        </template>
        <template v-else>
            The next campaign will be advertised soon.
        </template>
    </div>

    <hr>

    <div id="past-campaigns" class="pt-2">
        <h3>Attendance Statistics on Past Campaigns:</h3>
        <template v-if="campaigns && campaigns.length > 0">
            <div class='row'>
                <template v-for="campaign in campaigns">
                    <template v-if="!campaign.active || new Date(campaign.date) < new Date()">
                        <CampaignDetail :campaign="campaign" />
                    </template>
                </template>
            </div>
        </template>
    </div>
    <hr>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import CampaignDetail from '@/components/CampaignDetail.vue'

const store = useStore();
const toast = useToast();

let campaigns = ref(undefined);

onMounted(async () => {
    await getCampaigns();
})

async function getCampaigns() {
    const response = await store.dispatch('get', `campaigns/`);
    if (response.status == 200) {
        campaigns.value = response.data;
        return
    }
    toast.error('Failed to retrieve campaigns', {
        timeout: 3000
    });
}

</script>