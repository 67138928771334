<template>
  <div v-if="account">
    <div id="header">
      <h3 style="text-align: center">
        {{ account.first_name ? account.first_name : account.email }}'s Profile
      </h3>
      <hr />
    </div>

    <div id="user-profile" class="centered-content">
      <div class="nav nav-tabs" role="tablist">
        <button
          class="nav-link active"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Profile
        </button>
        <button
          class="nav-link"
          id="password-tab"
          data-bs-toggle="tab"
          data-bs-target="#password"
          type="button"
          role="tab"
          aria-controls="password"
          aria-selected="false"
        >
          Change Password
        </button>
        <button
          class="nav-link"
          id="municipal-tab"
          data-bs-toggle="tab"
          data-bs-target="#municipal_account_update"
          type="button"
          role="tab"
          aria-controls="municipal_account_update"
          aria-selected="false"
        >
          ELM Account
        </button>
      </div>
      <div class="tab-content mt-2">
        <!-- profile -->
        <div
          class="tab-pane fade show active"
          id="profile"
          aria-labelledby="profile-tab"
          role="tabpanel"
        >
          <UpdateProfile />
        </div>

        <!-- change password -->
        <div
          class="tab-pane fade"
          id="password"
          role="tabpanel"
          aria-labelledby="password-tab"
        >
          <ChangePassword />
        </div>

        <!-- ELM Account -->
        <div
          id="municipal_account_update"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="municipal-tab"
        >
          <UpdateELMEmail />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h5 {
  text-align: center;
}

img {
  height: 200px !important;
}

small {
  color: #808080;
  font-size: x-small;
}

.required-tip:before {
  content: "* ";
  color: red;
}

.required:after {
  content: " *";
  color: red;
}

label {
  font-weight: bold;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import UpdateELMEmail from "./components/UpdateELMEmail.vue";
import ChangePassword from "./components/ChangePassword.vue";
import UpdateProfile from "./components/UpdateProfile.vue";

let store = useStore();

let account = ref(undefined);

onMounted(async () => {
  getAccountFromStore();
});

function getAccountFromStore() {
  account.value = JSON.parse(JSON.stringify(store.state.account)); //TODO: find out a better way to copy object
}
</script>
