<template>
    <div class="centered-content">
        <h3>{{ id ? 'Update' : 'Create' }} New Dispute Criteria</h3>
    </div>
    <hr>

    <form @submit.prevent="submitForm" enctype="multipart/form-data" class="card card-body">
        <div class="mb-3">
            <label for="type" class="form-label">Dispute Type</label>
            <select id="type" v-model="criteria.dispute_type" class="form-select">
                <option v-for="t in types" :value="t.dispute_type">{{ t.dispute_type }}</option>
            </select>
            <small v-if="(errors && errors.dispute_type)" class="form-label text-danger col-12"
                v-for="error in errors.dispute_type">
                {{ error }}</small>
        </div>
        <div class="mb-3">
            <label for="header" class="form-label">Header</label>
            <input id="header" type="text" class="form-control" v-model="criteria.header" placeholder="Header" />
            <label v-if="errors && errors.header" v-for="error in errors.header" for="header"
            class="form-label text-danger">{{ error }}</label>
        </div>
        <div class="mb-3">
            <label for="editor" class="form-label">Editor</label>
            <div id="editor"></div>
            <label v-if="errors && errors.body" v-for="error in errors.body" for="editor"
            class="form-label text-danger">{{ error }}</label>
        </div>

        <div id="image_uploader" class="mb-3">
            <label for="bank_confirmation" class="form-label">Upload Bank Confirmation</label><br>
            <input id="bank_confirmation" @change="storeBank" class="form-control" type="file" accept="application/pdf">

            <label v-if="errors && errors.bank_confirmation" v-for="error in errors.bank_confirmation" for="bank_confirmation"
            class="form-label text-danger">{{ error }}</label>
        </div>
        <button class="btn btn-primary">Submit</button>
    </form>
</template>

<style scoped>

</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
import { editorQuill, getContentsQuill } from "@/components/quillManager";

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

let types = ref([])
let criteria = ref({
    dispute_type: 'undefined',
    header: '',
    body: 'undefined',
    bank_confirmation: undefined,
})
let errors = ref(undefined)

let id = route.params.id ? route.params.id : undefined

onMounted(async () => {
    await getDisputeTypes()
    if (id) {
        await getType()
    }
    editorQuill("#editor", criteria.value?.body);
})

async function getDisputeTypes() {
    const response = await store.dispatch('get', 'disputes/types/');
    console.log(response);
    if (response.status === 200) {
        types.value = response.data;
    }
    else {
        errors.value = response.data
    }
}

async function getType(){ 
    const response = await store.dispatch('get', `disputes/criteria/${id}/`)
    
    if (response.status === 200){
        criteria.value = response.data
        criteria.value.body = JSON.parse(criteria.value.body);
        criteria.value.bank_confirmation = undefined
    }
    else {
        toast.error(`Dispute Criteria not found`, {timeout: 5000});
    }
}

function storeBank(event) {
  criteria.value.bank_confirmation = event.target.files[0];
}

async function submitForm() {
  criteria.value.body = getContentsQuill("#editor");

  let formData = new FormData();
  formData.append("dispute_type", criteria.value.dispute_type);
  formData.append("header", criteria.value.header);
  formData.append("body", criteria.value.body);
  if (criteria.value.bank_confirmation && criteria.value.bank_confirmation != '') {
    formData.append("bank_confirmation", criteria.value.bank_confirmation);
  }
  let method = '';
  let url = '/disputes/criteria/';
  if (id) {
    //update
    method = 'put';
    url += `${id}/`;
    formData.append("id", id);
  }
  else {
    //new
    method = 'post';
  }
  const payload = {
    api: url,
    data: formData
  };

  const response = await store.dispatch(method, payload);
  if (response.status === 201 || response.status === 202) {
    toast.success(`Criteria created`, {
      timeout: 5000
    });
    router.push({ name: 'ListCriteria' });
  }
  else {
    errors.value = response.data;
  }
}
</script>