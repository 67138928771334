<template>
  <div class="centered-content">
    <h3 style="text-align: center">
      Petitions
      <router-link
        v-if="store.state.account && store.state.account.is_staff"
        class="ms-auto"
        :to="{ name: 'createPetition' }">
        <li class="material-icons text-success" style="cursor: pointer">post_add</li>
      </router-link>
    </h3>
  </div>
  <hr />
  <div class="w-auto">
    <template v-if="petitions.length > 0" v-for="(obj, idx) in petitions">
      <router-link
        class="card shadow mb-3"
        style="text-decoration: none; color: inherit"
        :to="{ name: 'detailedPetition', params: { slug: obj.slug } }">
        <div class="card-header">
          <h3>{{ obj.title }}</h3>
          <h6>{{ $filters.dateTime(obj.date_modified) }}</h6>
          <h6 v-if="obj.active">In Progress</h6>
          <h6 v-else>Ended</h6>
        </div>
        <div
          :id="'editor-' + idx"
          v-html="readOnlyQuill(obj.body)"
          class="card-body w-auto h-auto"
          style="overflow: auto"></div>
      </router-link>
      <hr v-if="idx + 1 < petitions.length" />
    </template>
    <template v-else>
      <p>No Active petitions. Stay tuned for more</p>
    </template>
  </div>
</template>

<style scoped>
.img {
  max-width: 100%;
  max-height: 100px;
  z-index: 0;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { readOnlyQuill } from "@/components/quillManager";

const store = useStore();

let petitions = ref([]);

onMounted(async () => {
  await getPetition();
});

async function getPetition() {
  const response = await store.dispatch(
    "get",
    store.state.account && store.state.account.is_staff ? "petitions/" : "petitions/public/"
  );
  if (response.status == 200) {
    petitions.value = response.data;
  }
}
</script>
