<template>
  <div class="card shadow-lg">
    <div class="card-header fw-bold centered-content">Change your password here</div>
    <form @submit.prevent="changePassword">
      <div class="card-body row">
        <div class="col-12 mt-2">
          <label for="password" class="form-label">Password</label>
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="password"
            placeholder="Please provide a strong password" />
          <ul v-if="errors">
            <li for="password" class="form-label text-danger col-12" v-for="error in errors">
              {{ error }}
            </li>
          </ul>
        </div>
        <div class="col-12 mt-2">
          <label for="password2" class="form-label">Confirm Password</label>
          <input
            id="password2"
            type="password"
            class="form-control"
            v-model="password2"
            placeholder="Retype Password" />
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary w-100" type="submit">Update Password</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
label {
  font-weight: bold;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";

const store = useStore();
const router = useRouter();
const route = useRoute();
let toast = useToast();

let password = ref();
let password2 = ref();
let errors = ref({});

onMounted(async () => {});

async function changePassword() {
  if (password.value === password2.value) {
    errors.value = {};
    const payload = {
      api: "account/password/",
      data: { password: password.value },
    };

    const response = await store.dispatch("post", payload);

    if (response.status === 200) {
      toast.success("Successfully updated password", {
        timeout: 3000,
      });

      router.push({
        name: "home",
      });

      return;
    }

    if (response.status === 400) {
      errors.value = response.data;
      toast.error(`Unexpected error when updating password: ${response.status}`, {
        timeout: 3000,
      });
      return;
    }
  } else {
    errors.value["password"] = ["Password provided must match."];
  }
}
</script>
