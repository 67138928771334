<template>
    <div class="centered-content">
        <template v-if="store.state.isAuthenticated == true">
            <div v-if="!store.state.profileCompleted" class="centered-content">
                <div>Please complete profile to submit a complaint</div>
                <router-link to="/profile" custom v-slot="{ navigate }">
                    <button class="btn btn-primary w-100" @click="navigate" @keypress.enter="navigate"
                        role="link">Update Profile</button>
                </router-link>
            </div>

            <form v-else @submit.prevent="submitForm" enctype="multipart/form-data">
                <div class="mb-3">
                    <h3 class="text-center">Lodge Complaint</h3>
                    <hr>
                    <label for="desc" class="form-label">Complaint Type</label>
                    <select v-model="complaint.complaint_type" class="form-select">
                        <option v-for="complaintType in types">{{ complaintType.complaint_type }}</option>
                    </select>
                    <small v-if="(errors && errors.complaint_type)" class="form-label text-danger col-12"
                        v-for="error in errors.complaint_type">
                        {{ error }}</small>
                </div>
                <div class="mb-3">
                    <label for="desc" class="form-label">Description</label>

                    <textarea v-model="complaint.description" type="text" class="form-control" id="desc"
                        aria-describedby="descHelp" rows="8" maxlength="1000" placeholder="Provide as much detail about the complaint as possible here"></textarea>
                        <small v-if="(errors && errors.description)" class="form-label text-danger col-12"
                        v-for="error in errors.description">
                        {{ error }}</small>
                </div>
                <div class="mb-3">
                    <div class="btn btn-success w-100 mb-3" @click="getLocation">
                        Get Location
                    </div>
                    <small v-if="locationError" class="form-label text-danger centered-content"> {{
                        locationError
                    }}</small>
                    <div style="height: 300px; z-index: 0;" id="map"></div>
                    <small v-if="(errors && errors.longitude)" class="form-label text-danger col-12"
                        v-for="error in errors.longitude">
                        {{ error }}</small>
                </div>
                <div class="mb-3">
                    <label for="imageMultiple" class="form-label">Upload Images</label>
                    <input @change="storeImages" class="form-control" type="file" id="imageMultiple" multiple
                        accept="image/*">
                </div>

                <div class="mb-3">
                    <label for="videoSingle" class="form-label">Upload Video</label>
                    <input @change="storeVideo" class="form-control" type="file" id="videoSingle"
                        accept="video/*">
                </div>

                <button type="submit" class="btn btn-primary w-100">Submit</button>

            </form>
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

const toast = useToast();
const store = useStore();
const router = useRouter();

const markerOptions = {
    title: "Complaint Location",
    clickable: true,
    draggable: false
};

let types = ref({});
let images = ref({});
let video = ref({});
let complaint = ref({
    complaint_type: '',
    description: '',
    latitude: 0,
    longitude: 0,
});
let errors = ref({});
let locationError = ref();
let map = null;
let marker = null;
let currentZoom = 13;

onMounted(async () => {
    if (store.state.isAuthenticated) {
        if (store.state.profileCompleted) {
            const response = await store.dispatch('get', 'complaints/types/');
            types.value = response.data;
            createMap();
        }

        return;
    }

    toast.error('Please register an account before you can create an complaint', {
        timeout: 3000
    });
    router.push({ name: "registration" });
})

async function submitForm() {
    errors.value = {};
    let formData = new FormData();
    formData.append("complaint_type", complaint.value.complaint_type);
    formData.append("description", complaint.value.description);
    formData.append("longitude", parseFloat(complaint.value.longitude).toFixed(6));
    formData.append("latitude", parseFloat(complaint.value.latitude).toFixed(6));

    if (images.value && images.value.length > 0) {
        for (let i = 0; i < images.value.length; i++) {
            formData.append('images', images.value[i]);
        }
    }

    if (video.value && video.value.length > 0) {
        for (let i = 0; i < video.value.length; i++) {
            formData.append('video', video.value[i]);
        }
    }

    const payload = {
        api: 'complaints/public/',
        data: formData
    }
    const response = await store.dispatch('post', payload);

    if (response.status == 201) {
        toast.success('Successfully created complaint', {
            timeout: 3000
        });
        router.push({ name: 'detailedComplaint', params: { id: response.data.id } });
        return;
    }

    toast.error(`Error creating complaint, error code: ${response.status}`, {
        timeout: 3000
    });

    errors.value = response.data;
}

function storeImages(event) {
    images.value = event.target.files;
}

function storeVideo(event) {
    video.value = event.target.files;
}

function createMap() {

    if (map) {
        return;
    }

    map = L.map("map");

    map.on('click', function (e) {
        complaint.value.latitude = e.latlng.lat;
        complaint.value.longitude = e.latlng.lng;

        setMarker(complaint.value.latitude, complaint.value.longitude);
    });

    map.on('zoomend', function () {
        currentZoom = map.getZoom();
    });

    L.Control.geocoder({
        defaultMarkGeocode: false
    }).on('markgeocode', function (e) {
        complaint.value.latitude = e.geocode.properties.lat;
        complaint.value.longitude = e.geocode.properties.lon;
        setMarker(complaint.value.latitude, complaint.value.longitude);
    }).addTo(map);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    // show general vanderbijlpark area on map when creating it, but don't set marker
    map.setView([-26.706238, 27.835898], currentZoom);
}

function setMarker(lat, long) {
    if (map) {
        locationError.value = '';
        if (marker) {
            map.removeLayer(marker);
        }

        map.setView([lat, long], currentZoom);
        marker = L.marker([lat, long], markerOptions).addTo(map);
    }
}

function getLocation() {
    locationError.value = '';
    // if the browser supports geolocation, get the user's location
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            onSuccess,
            onError
        );
        return;
    }

    toast.error('Your browser does not support geolocation', {
        timeout: 3000
    });
}

function onSuccess(position) {
    complaint.value.latitude = position.coords.latitude;
    complaint.value.longitude = position.coords.longitude;
    setMarker(complaint.value.latitude, complaint.value.longitude);
}

function onError(error) {
    if (error.code == 1) {
        locationError.value = "You have not given permission to access your location.";
        return;
    }

    if (error.code == 2) {
        locationError.value = "Your location is unavailable.";
        return;
    }

    if (error.code == 3) {
        locationError.value = "The request to get your location timed out.";
        return;
    }

    locationError.value = "An unknown error occurred.";
}


</script>