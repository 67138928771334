<template>
  <div class="card">
    <div class="card-header fw-bold">
      To request Emfuleni to send your ELM Invoices/Statements of your account
      <br />
      ({{ store.state.account.municipal_account_number }}) to a specific email, use the form below:
    </div>
    <form @submit.prevent="updateEmail">
      <div class="card-body">
        <label for="new-password">Please review / add new email for ELM Accounts</label>
        <input id="new-password" class="form-control" type="email" v-model="newEmail" required />
      </div>
      <div class="card-footer">
        <button class="btn btn-primary w-100">Request Email Update</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
label {
  font-weight: bold;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";

let store = useStore();
let router = useRouter();
let route = useRoute();
let toast = useToast();

let newEmail = ref(undefined);

onMounted(async () => {
  newEmail.value = store.state.account.email;
});

async function updateEmail() {
  const payload = {
    api: "account/new-email/",
    data: {
      email: newEmail.value,
    },
  };
  const response = await store.dispatch("post", payload);
  if (response.status === 200) {
    toast.success("Successfully send email update request", {
      timeout: 10000,
    });
    let el = document.getElementById("municipal_account_update");
    el.classList.remove("active");
    el.classList.add("fade");

    el = document.getElementById("profile");
    el.classList.remove("fade");
    el.classList.add("active");

    el = document.getElementById("profile-tab");
    el.classList.add("active");

    el = document.getElementById("municipal-tab");
    el.classList.remove("active");
  } else {
    toast.error("Could not email request, please try again later.", {
      timeout: 10000,
    });
  }
}
</script>
