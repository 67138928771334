<template>
    <div class="row centered-content">
        <div v-if="poll">
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <h3 class="text-center fw-bold">{{ poll.title }}</h3>
                    <hr>
                        <h6 class="ms-auto" style="font-size: 12px;"><strong>Date created:</strong> {{
                                $filters.date(poll.date_created)
                        }} </h6>
                        <h6 class="ms-auto" style="font-size: 12px;"><strong>Created By:</strong> {{
                                poll.created_by
                        }} </h6>
                    <div id="editor" v-html="readOnlyQuill(poll.body)"></div>
                    <small v-if="!poll.active">This poll is currently inactive, but you can still view the results</small>
                </div>
                <template v-if="!store.state.account || !poll.active || poll.has_voted">
                    <div class='card-body' style="padding:10px" v-for="q in poll.questions">
                        <h5 class="fw-bold">Question: {{ q.question }}</h5>
                        <div style="padding:5px" v-for="a in q.answers" class="border">
                            <h6 class="fw-bold">Answer: {{ a.answer }}</h6>
                            <small>Votes: {{ a.votes }}</small>
                            <div class="progress">
                                <div class="progress-bar bg-primary" role="progressbar"
                                    :style="'width:' + a.votes / q.total_votes * 100 + '%'" :aria-valuenow="a.votes"
                                    aria-valuemin="0" :aria-valuemax="q.total_votes">
                                    {{(a.votes / q.total_votes * 100).toFixed(1) + '%'}}
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div v-if="!store.state.account && poll.active" class="card-footer">
                        <p>Please log in to participate in poll</p>
                    </div>
                </template>
                <form v-else @submit.prevent="submitForm">
                    <div class='card-body'>
                        <div v-for="q, idx in poll.questions" class='row'>
                            <hr v-if="idx > 0">
                            <div class='col-12'>
                                <h3 class="fw-bold">{{ q.question }}</h3>
                            </div>
                            <div class="col-12">
                                <div class="d-block">
                                    <template v-for="a in q.answers">
                                        <input :name="'q-' + q.id" :id="'q-' + q.id + '-a-' + a.id" type="radio"
                                            :value="a.id" v-model="q.answer" required>
                                        <label :for="'q-' + q.id + '-a-' + a.id" class="ms-1 form-label">{{ a.answer
                                        }}</label><br>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex">
                        <button class="btn btn-primary ms-auto w-100">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <div v-else>
            <div class='card shadow-lg'>
                <div class='card-header'>
                    <div class='row'>
                        <h3>Could not find poll</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
small {
    color: #808080;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { readOnlyQuill } from "@/components/quillManager";


const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();

let slug = route.params.slug ? route.params.slug : undefined
let poll = ref(undefined);

onMounted(async () => {
    await GetPoll();
})

async function GetPoll() {
    if (slug) {
        const response = await store.dispatch(
            'get', 
            store.state.account && store.state.account.is_staff ? `polls/${slug}/` : `polls/public/${slug}/`
            );
        console.log(response);
        if (response.status === 200) {
            // make sure that only one poll is returned and not an array of polls
            if (!Array.isArray(response.data)) {
                poll.value = response.data;
                return;
            }
        }

        if (response.status === 404) {

            toast.error(`Poll '${slug}' was not found`, {
                timeout: 3000
            });

            router.push({ name: 'listPolls' });
            return;
        }

        toast.error(`Unexpected error getting ${slug}: ${response.status}`, {
            timeout: 3000
        });
        router.push({ name: 'polls' });
        console.log(response.data);
    }
}

async function submitForm() {
    if (!store.state.account) {
        toast.error('Only logged in users can participate', {
            timeout: 3000
        });
        return;
    }

    const payload = {
        api: `polls/answer/${slug}/`,
        data: poll.value
    }

    const response = await store.dispatch('post', payload);

    if (response.status == 200) {
        toast.success(`Successfully Voted`, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 400) {
        toast.error(`Please complete the form`, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 403) {
        toast.error(response.data, {
            timeout: 3000
        });
        await GetPoll();
        return;
    }

    if (response.status == 409) {
        toast.error(`Already Voted`, {
            timeout: 3000
        });

        await GetPoll();
        return;
    }
}

</script>