<template>
    <footer class="footer pt-1">
        <div class="centered-content" style="padding: 2px;">
            <div style="height: 25px;">
                <router-link class="link" :to="{ name: 'about' }" @click="toggleNav()">About ERPA</router-link> |
                <router-link class="link" :to="{ name: 'policies' }" @click="toggleNav()">Policies</router-link> |
                <router-link class="link" :to="{ name: 'legislation' }" @click="toggleNav()">Legislation</router-link> |
                <router-link class="link" :to="{ name: 'ContactUs' }" @click="toggleNav()">Contact Us</router-link> |
                <router-link class="link" :to="{ name: 'LandingPage' }" @click="toggleNav()">Support</router-link>
            </div>
            <div style="height: 25px;" class="d-print-none d-none d-sm-none d-md-block">
                <small>
                    ERPA is incorporated as a non-profit company in terms of the
                    <a class="link" href="https://www.saica.org.za/resources/93342" target="_blank">
                        Companies Act, 2008 (Act 71 of 2008)
                    </a> of South Africa
                </small>
            </div>
        </div>
    </footer>
</template>

<style>
.footer {
    position: fixed;
    font-size: smaller;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(46, 163, 242);
    color: white;
    z-index: 10;
}

.link {
    color: white;
}
</style>

<script setup>
function toggleNav() {
    try {
        var nav = document.getElementById("navbarToggler");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
    } catch { }
};
</script>