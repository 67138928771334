import Quill from 'quill';
import Delta from 'quill-delta';

function readOnlyQuill(contents) {
  let delta = new Delta(JSON.parse(contents));
  let tempCont = document.createElement("div");
  (new Quill(tempCont)).setContents(delta);
  return tempCont.getElementsByClassName("ql-editor")[0].innerHTML;
}


function editorQuill(el, content = undefined) {
  let quill = new Quill(el, {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['link'],
        ['image'],
        ['video'],
        ['clean'],                                       // remove formatting button
      ],
      imageResize: {},
      videoResize: {},
    },
    theme: 'snow'
  });

  let toolbar = quill.container.previousSibling;
  toolbar.querySelector('button.ql-bold').setAttribute('title', 'bold')
  toolbar.querySelector('button.ql-italic').setAttribute('title', 'italic')
  toolbar.querySelector('button.ql-underline').setAttribute('title', 'underline')
  toolbar.querySelector('button.ql-strike').setAttribute('title', 'strike')
  toolbar.querySelector('button.ql-blockquote').setAttribute('title', 'blockquote')
  toolbar.querySelector('button.ql-code-block').setAttribute('title', 'code')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-list').setAttribute('title', 'list')
  toolbar.querySelector('button.ql-script').setAttribute('title', 'script')
  toolbar.querySelector('button.ql-indent').setAttribute('title', 'indent')
  toolbar.querySelector('button.ql-direction').setAttribute('title', 'direction')
  toolbar.querySelector('button.ql-header').setAttribute('title', 'header')
  toolbar.querySelector('button.ql-link').setAttribute('title', 'link')
  toolbar.querySelector('button.ql-image').setAttribute('title', 'image')
  toolbar.querySelector('button.ql-video').setAttribute('title', 'video')
  toolbar.querySelector('button.ql-clean').setAttribute('title', 'clean')

  try {
    if (content) {
      quill.setContents(content);
    }
  }
  catch { }
}

function getContentsQuill(el) {
  var linkBlot = Quill.find(document.querySelector(el));
  return JSON.stringify(linkBlot.getContents().ops);
}

export {
  readOnlyQuill,
  editorQuill,
  getContentsQuill,
}