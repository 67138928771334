<template>
  <div class='row'>
    <div class='col-12'>
      <div id="create" class='card'>
        <div class='card-header d-flex'>
          <h3><span v-if="!slug">New</span><span v-else>Update</span> Petition</h3>
          <li v-if="slug" class="ms-auto material-icons text-danger" @click="showModal('Delete Petition', 'Are you sure you want to delete this petition?', 'Yes', 'No', () => deletePetition())">delete</li>
        </div>
        <form @submit.prevent="submitForm">
          <div class='card-body'>
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input id="title" type="text" class="form-control" v-model="petition.title" placeholder="title" />
              <label v-if="errors && errors.title" v-for="error in errors.title" for="title"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div id="quill-container" class="mb-3">
              <label for="editor" class="form-label">Editor</label>
              <div id="editor"></div>
              <label v-if="errors && errors.body" v-for="error in errors.body" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3">
              <label for="goal" class="form-label">Vote Goal</label>
              <input id="goal" type="number" min="0" class="form-control" v-model="petition.goal" />
              <label v-if="errors && errors.goal" v-for="error in errors.goal" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
            <div class="mb-3 form-check">
              <input id="active" type="checkbox" class="form-check-input" v-model="petition.active" />
              <label for="active" class="form-check-label">Active</label>
              <label v-if="errors && errors.active" v-for="error in errors.active" for="editor"
                class="form-label text-danger">{{ error }}</label>
            </div>
          </div>
          <div class='card-footer'>
            <button class="btn btn-primary w-100">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ql-editor iframe {
  pointer-events: none !important;
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
import { showModal } from '../../components/customModal.js'
import { editorQuill, getContentsQuill } from "@/components/quillManager";

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast();

let slug = ref(route.params.slug ? route.params.slug : undefined);
let petition = ref({
  title: '',
  body: '',
  goal: '',
  active: true,
});
let errors = ref(undefined);

onMounted(async () => {
  if (slug.value) {
    await getPetition();
  }
  editorQuill("#editor", petition.value?.body);
})

async function getPetition() {
  const response = await store.dispatch('get', `petitions/${slug.value}/`);
  if (response.status == 200) {
    let data = response.data;
    try {
      data.body = JSON.parse(data.body);
    }
    catch {
      data.body = [];
    }
    petition.value = data;
  }
  else if (response.status == 404) {
    toast.error(`Petition ${slug.value} was not found`, {
      timeout: 5000,
    })
    router.push({ name: 'listPetition' });

  }
}

async function submitForm() {
  petition.value.body = getContentsQuill('#editor');

  let method = '';
  let url = '/petitions/';
  if (slug.value) { //update
    method = 'put';
    url += `${slug.value}/`;
  }
  else { //new
    method = 'post';
  }
  const payload = {
    api: url,
    data: petition.value
  }

  const response = await store.dispatch(method, payload);

  if (response.status == 200 || response.status == 201) {
    const msg = slug.value ? 'Updated' : 'Created';
    toast.success(`Petition ${msg}`, {
      timeout: 5000
    });
    slug.value = response.data.slug;
    router.push({ name: 'updatePetition', params: { slug: response.data.slug } });
    await getPetition();
  }
  else {
    toast.error(`Error ${response.status}`, {
      timeout: 5000
    });
    errors.value = response.data;
  }
}

async function deletePetition() {
  const response = await store.dispatch('delete', `petitions/${slug.value}/`);

  if (response.status == 202) {
    router.push({ name: 'listPetition' });
  }
}

</script>