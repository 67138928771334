<template>
  <form @submit.prevent="updateProfile">
    <div class="card">
      <div class="card-header fw-bold">
        Update your profile in order to lodge complaints and disputes
      </div>

      <div class="card-body">
        <div id="email" class="mb-3">
          <label class="required" for="email">Email</label>
          <div><small>Read-only field</small></div>
          <input
            id="email"
            disabled
            class="form-control"
            v-model="account.email"
            readonly
            placeholder="Email"
          />
        </div>

        <div id="first_name" class="mb-3">
          <label class="required" for="first_name">First Name</label>
          <div v-if="first_name_set"><small>Read-only field</small></div>
          <input
            v-if="!first_name_set"
            id="first_name"
            class="form-control"
            v-model="account.first_name"
            placeholder="First Name"
          />
          <input
            v-else
            id="first_name"
            class="form-control"
            readonly
            disabled
            v-model="account.first_name"
            placeholder="First Name"
          />
        </div>

        <div id="last-name" class="mb-3">
          <label for="last_name">Last Name</label>
          <input
            id="last_name"
            class="form-control"
            v-model="account.last_name"
            placeholder="Last Name"
          />
          <ul v-if="errors && errors.last_name">
            <li
              for="last_name"
              class="form-label text-danger col-12"
              v-for="error in errors.last_name"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="ownership-status" class="mb-3">
          <label class="required" for="ownership">Ownership Status</label>
          <select
            id="ownership"
            class="form-select"
            v-model="account.ownership"
          >
            <option class="form-control" disabled>Ownership Status</option>
            <option class="form-control" value="Personal">Personal</option>
            <option class="form-control" value="Company">Company</option>
            <option class="form-control" value="Tenant">Tenant</option>
          </select>
          <ul v-if="errors && errors.ownership">
            <li
              for="ownership"
              class="form-label text-danger col-12"
              v-for="error in errors.ownership"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="primary-contact" class="mb-3">
          <label class="required" for="primary_contact">Primary Contact</label>
          <input
            id="primary_contact"
            class="form-control"
            type="tel"
            v-model="account.primary_contact"
            v-maska="primary_bind"
            data-maska="(###) ###-####"
            placeholder="eg. (012) 345-6789"
          />
          <ul v-if="errors && errors.primary_contact">
            <li
              class="form-label text-danger col-12"
              v-for="error in errors.primary_contact"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="alternative-contact" class="mb-3">
          <label for="alternative_contact">Alternative Contact</label>
          <input
            id="alternative_contact"
            class="form-control"
            type="tel"
            v-model="account.alternative_contact"
            v-maska="alternative_bind"
            data-maska="(###) ###-####"
            placeholder="eg. (012) 345-6789"
          />
          <ul v-if="errors && errors.alternative_contact">
            <li
              class="form-label text-danger col-12"
              v-for="error in errors.alternative_contact"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="identification-type" class="mb-3">
          <label class="required" for="identification_type"
            >Identification Type</label
          >
          <select
            @change="onIdTypeChange"
            id="identification_type"
            class="form-select"
            v-model="account.identification_type"
          >
            <option class="form-select" value="South African ID">
              South African ID
            </option>
            <option class="form-select" value="Passport">Passport</option>
          </select>
          <ul v-if="errors && errors.identification_type">
            <li
              class="form-label text-danger col-12"
              v-for="error in errors.identification_type"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="identification" class="mb-3">
          <template v-if="account.identification_type == 'Passport'">
            <td>
              <label class="required" for="identification_number"
                >Passport Number</label
              >
            </td>
            <input
              id="identification_number"
              class="form-control"
              type="text"
              v-model="account.identification_number"
              maxlength="9"
              placeholder="Passport Number"
              @keypress="handleInput($event)"
            />
            <ul v-if="errors && errors.identification_number">
              <li
                for="identification_number"
                class="form-label text-danger col-12"
                v-for="error in errors.identification_number"
              >
                {{ error }}
              </li>
            </ul>
          </template>
          <template v-else>
            <label class="required" for="identification_number"
              >Identification Number</label
            >
            <input
              id="identification_number"
              class="form-control"
              type="text"
              v-model="account.identification_number"
              v-maska
              data-maska="###### #### ###"
              maxlength="15"
              placeholder="Identification Number"
              @keypress="handleInput($event)"
            />
            <ul v-if="errors && errors.identification_number">
              <li
                for="identification_number"
                class="form-label text-danger col-12"
                v-for="error in errors.identification_number"
              >
                {{ error }}
              </li>
            </ul>
          </template>
        </div>

        <div id="municipal-account-number" class="row mb-3">
          <div class="col-6">
            <label class="required" for="municipal_account_number"
              >Municipal Account Number (ELM)</label
            >
            <br />
            <small>
              Required to lodge a
              <b>Billing</b>
              dispute
            </small>
          </div>
          <div class="col-6">
            <input
              id="municipal_account_number"
              class="form-control"
              type="text"
              v-model="account.municipal_account_number"
              v-maska
              data-maska="#### ####"
              maxlength="9"
              placeholder="Municipal Account Number"
              @keypress="isNumber($event)"
            />
            <ul v-if="errors && errors.municipal_account_number">
              <li
                class="form-label text-danger col-12"
                v-for="error in errors.municipal_account_number"
              >
                {{ error }}
              </li>
            </ul>
          </div>
        </div>

        <div id="electrical-meter-type" class="mb-3">
          <label for="electrical_meter_type" class="required"
            >Electrical Meter Type</label
          >
          <select
            id="electrical_meter_type"
            class="form-select"
            v-model="account.electrical_meter_type"
          >
            <option
              v-for="type in elecTypes"
              class="form-select"
              :value="type.id"
            >
              {{ type.type }}
            </option>
          </select>
          <ul v-if="errors && errors.electrical_meter_type">
            <li
              class="form-label text-danger col-12"
              v-for="error in errors.electrical_meter_type"
            >
              {{ error }}
            </li>
            cd
          </ul>
        </div>

        <div id="electricity-meter-number" class="mb-3">
          <label class="required" for="electricity_meter_number"
            >Electrical Meter Number</label
          >
          <input
            id="electricity_meter_number"
            class="form-control"
            type="text"
            v-model="account.electricity_meter_number"
            maxlength="12"
            placeholder="Electrical Meter Number"
          />
          <ul v-if="errors && errors.electricity_meter_number">
            <li
              class="form-label text-danger col-12"
              v-for="error in errors.electricity_meter_number"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="water-meter-number" class="mb-3">
          <label class="required" for="water_meter_number"
            >Water Meter Number</label
          >
          <br />
          <small>
            Required to lodge
            <b>Water/Sewerage</b>
            disputes or complaints
          </small>

          <input
            id="water_meter_number"
            class="form-control"
            type="text"
            v-model="account.water_meter_number"
            maxlength="12"
            placeholder="Water Meter Number"
          />
          <ul v-if="errors && errors.water_meter_number">
            <li
              for="water_meter_number"
              class="form-label text-danger col-12"
              v-for="error in errors.water_meter_number"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="unit-number" class="mb-3">
          <label for="unit_number">Unit Number</label>

          <input
            id="unit_number"
            class="form-control"
            v-model="account.unit"
            maxlength="256"
            placeholder="Unit Number"
          />
          <ul v-if="errors && errors.unit">
            <li
              for="unit_number"
              class="form-label text-danger col-12"
              v-for="error in errors.unit"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="complex-name" class="mb-3">
          <label for="complex">Complex Name</label>
          <input
            id="complex"
            class="form-control"
            v-model="account.complex"
            maxlength="256"
            placeholder="Complex Name"
          />
          <ul v-if="errors && errors.complex">
            <li
              for="complex"
              class="form-label text-danger col-12"
              v-for="error in errors.complex"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="street-number" class="mb-3">
          <label class="required" for="street_number">Street Number</label>
          <br />
          <small
            >This must be the street number reflected on your ELM account</small
          >
          <input
            id="street_number"
            class="form-control"
            type="text"
            v-model="account.street_number"
            maxlength="256"
            placeholder="Street Number"
          />
          <ul v-if="errors && errors.street_number">
            <li
              for="street_number"
              class="form-label text-danger col-12"
              v-for="error in errors.street_number"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="street-name" class="mb-3">
          <label class="required" for="street_name">Street Name</label>
          <br />
          <small
            >This must be the street name reflected on your ELM account</small
          >
          <input
            id="street_name"
            class="form-control"
            type="text"
            v-model="account.street_name"
            maxlength="256"
            placeholder="Street Name"
          />
          <ul v-if="errors && errors.street_name">
            <li
              for="street_name"
              class="form-label text-danger col-12"
              v-for="error in errors.street_name"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="suburb-name" class="mb-3">
          <label class="required" for="suburb">Suburb</label>
          <select id="suburb" class="form-select" v-model="account.suburb">
            <option
              v-for="sub in suburbs"
              class="form-select"
              :value="sub.suburb"
            >
              {{ sub.suburb }}, {{ sub.postcode }}
            </option>
          </select>
          <ul v-if="errors && errors.suburb">
            <li
              for="suburb"
              class="form-label text-danger col-12"
              v-for="error in errors.suburb"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="town-name" class="mb-3">
          <label class="required" for="town">Town</label>
          <select id="town" class="form-select" v-model="account.town">
            <option
              v-for="town in towns"
              class="form-select"
              :value="town.town"
            >
              {{ town.town }}
            </option>
          </select>
          <ul v-if="errors && errors.town">
            <li
              for="town"
              class="form-label text-danger col-12"
              v-for="error in errors.town"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-primary w-100" type="submit">
          Update Profile
        </button>
      </div>
    </div>
  </form>

  <div id="fine-print">
    <small class="required-tip"
      >Required to lodge complaints and disputes</small
    >
  </div>
</template>

<style scoped>
h5 {
  text-align: center;
}

img {
  height: 200px !important;
}

small {
  color: #808080;
  font-size: x-small;
}

.required-tip:before {
  content: "* ";
  color: red;
}

.required:after {
  content: " *";
  color: red;
}

label {
  font-weight: bold;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { vMaska } from "maska";
import { reactive } from "vue";
import { useToast } from "vue-toastification";

let store = useStore();
let router = useRouter();
let route = useRoute();
let toast = useToast();

let primary_bind = reactive({});
let alternative_bind = reactive({});
let first_name_set = ref(false);

let account = ref({});
let suburbs = ref([]);
let towns = ref([]);
let elecTypes = ref([]);
let errors = ref({});
let accountChanged = ref(false);

onMounted(async () => {
  const suburbPromise = getSuburbs();
  const townPromise = getTowns();
  const elecTypePromise = getElectricalMeterTypes();

  getAccountFromStore();

  Promise.all([suburbPromise, townPromise, elecTypePromise]);
});

function getAccountFromStore() {
  account.value = JSON.parse(JSON.stringify(store.state.account)); //TODO: find out a better way to copy object

  changeIdType(account.value.identification_type);
  if (account.value.first_name) {
    first_name_set.value = true;
  }
}

function accountHasChange() {
  accountChanged.value =
    JSON.stringify(account.value) !== JSON.stringify(store.state.account);
  // when something changes in the form, clear all errors
  errors.value = {};
}

function onIdTypeChange(event) {
  changeIdType(event.target.value, true);
}

function changeIdType(type, clearIdNumber = false) {
  if (clearIdNumber) {
    account.value.identification_number = "";
  }

  account.value.identification_type = type;
}

function handleInput(e) {
  if (account.identification_type === "South African ID") {
    return isNumber(e);
  }
  return isAlphaNumeric(e);
}

function isNumber(e) {
  //TODO see if this can be done with regex
  // Only ASCII character in that range allowed
  const ASCIICode = e.which ? e.which : e.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    e.preventDefault();
  } else {
    return true;
  }
}

function isAlphaNumeric(e) {
  //TODO see if this can be done with regex
  const controlKeys = [
    8, //Backspace
    9, //Tab
    35, //End
    36, //Home
    37, //Left
    39, //Right
    46, //Delete
  ];

  const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
  const isAlphaNum =
    (keyCode >= 48 && keyCode <= 57) ||
    (keyCode >= 65 && keyCode <= 90) ||
    keyCode == 32 ||
    (keyCode >= 97 && keyCode <= 122) ||
    (controlKeys.indexOf(e.keyCode) !== -1 && e.charCode != e.keyCode);

  if (isAlphaNum) {
    return isAlphaNum;
  }

  e.preventDefault();
}

async function getSuburbs() {
  const response = await store.dispatch("get", "account/suburbs/");

  if (response.status === 200) {
    suburbs.value = response.data;
    return;
  }

  toast.error("Failed to retrieve suburbs", {
    timeout: 3000,
  });
}

async function getTowns() {
  const response = await store.dispatch("get", "account/towns/");

  if (response.status === 200) {
    towns.value = response.data;
    return;
  }

  toast.error("Failed to retrieve towns", {
    timeout: 3000,
  });
}

async function getElectricalMeterTypes() {
  const response = await store.dispatch(
    "get",
    "account/electrical-meter-types/"
  );

  if (response.status === 200) {
    elecTypes.value = response.data;
    return;
  }

  toast.error("Failed to retrieve meter types", {
    timeout: 3000,
  });
}

async function updateProfile() {
  accountHasChange();
  if (!accountChanged.value) {
    toast.error("No changes was made to your profile, update a value", {
      timeout: 3000,
    });
    return;
  }

  errors.value = {};

  sanitizePhoneNumbers();

  const data = {
    api: "account/profile/",
    data: account.value,
  };

  const response = await store.dispatch("put", data);

  if (response.status === 400) {
    errors.value = response.data;
    toast.error("Failed to update profile", {
      timeout: 3000,
    });
    let faultyKey = Object.keys(errors.value)[0];
    let faultyInputElements = document.getElementById(faultyKey);
    faultyInputElements.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
    return;
  }

  if (response.status === 200) {
    await store.dispatch("getProfile");

    toast.success("Successfully updated profile", {
      timeout: 3000,
    });

    getAccountFromStore();

    router.push({ name: "UpdateProfileSuccess" });
    return;
  }

  toast.error(`Unexpected response code from backend: ${response.status}`, {
    timeout: 3000,
  });
  console.log(response.data);
}

function sanitizePhoneNumbers() {
  if (primary_bind.completed) {
    account.value.primary_contact = primary_bind.masked;
  }

  if (alternative_bind.completed) {
    account.value.alternative_contact = alternative_bind.masked;
  }
}
</script>
