<template>
  <section class="row centered-content">
    <div class="col-sm-12 col-md-6">
      <h1 class="text-center">Welcome to ERPA</h1>
      <h3 class="text-center">
        Home of the Emfuleni Ratepayers' Association NPC
      </h3>
      <h5 class="text-center">Taking steps towards a flourishing community</h5>
      <img class="mt-3 w-100" src="./photo_2020-02-21_16-35-04.png" alt="" />
    </div>
  </section>

  <div class="row centered-content">
    <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
      <div class="mt-5 accordion accordion-flush">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <h4>ERPA's Mandate</h4>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
          >
            <div class="accordion-body">
              <ul>
                <li>
                  To halt the functional collapse of the local municipality and
                  restore both structure and function of effective governance to
                  create an enabling environment for the community to recover,
                  lost jobs to be restored and new jobs created, and confidence
                  to return such that an inclusive economy can flourish in the
                  Emfuleni area.
                </li>
                <li>
                  To fix the burning challenges left in the wake of the Emfuleni
                  Local Municipality (ELM) failures.
                </li>
                <li>
                  Implement processes & procedures that will facilitate
                  continual improvement of maintenance, quality and delivery of
                  municipal services and infrastructure, professionally,
                  transparently and cost effectively.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <h4>Vision</h4>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
          >
            <div class="accordion-body">
              <div>
                The scope of our mandate covers the following key performance
                areas:
              </div>
              <ul>
                <li>
                  Services - Critical municipal services, including refuse
                  removal, electricity, sewage and water.
                </li>
                <li>
                  Maintenance - Including (but not limited to) potholes, street
                  lights, burst water pipes, municipal areas (e.g. parks,
                  sidewalks, servitude's).
                </li>
                <li>
                  Security - Including (but not limited to) armed response,
                  foot-patrols, boom-closures, traffic calming.
                </li>
                <li>
                  Infrastructure - Including (but not limited to) sewerage
                  plants, electrical sub-stations and related network
                  reticulation, water and sanitation.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <h4>Open Invitation</h4>
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
          >
            <div class="accordion-body">
              <p>
                We extend an open invitation to Emfuleni ratepayers and
                residents to join us in the quest to restore Emfuleni to its
                former splendor. Please
                <router-link :to="{ name: 'registration' }"
                  >register</router-link
                >
                for free and immediately benefit from the multitude of features
                and functions that will assist you in dealing with the municipal
                issues you are faced with.
              </p>
              <div class="bold-text">
                The following services are available to you when registered:
              </div>
              <ul>
                <li>
                  Relevant
                  <router-link :to="{ name: 'listNews' }"
                    >newsletters</router-link
                  >
                  and articles about burning issues.
                </li>
                <li>
                  A convenient platform for submitting
                  <router-link :to="{ name: 'lodgeDispute' }"
                    >disputes</router-link
                  >
                  (in accordance with national, provincial and local
                  legislation) and other
                  <router-link :to="{ name: 'lodgeComplaint' }"
                    >forms.</router-link
                  >
                </li>
                <li>
                  A means to
                  <router-link :to="{ name: 'LandingPage' }"
                    >communicate</router-link
                  >
                  emergencies relating to municipal infrastructure and/or
                  services.
                </li>
                <li>
                  A growing library of relevant
                  <router-link :to="{ name: 'listDocument' }"
                    >documentation</router-link
                  >
                  conveniently in one place. No need to scour the internet for
                  relevant documentation and potentially finding misinformation.
                </li>
                <li>
                  A
                  <router-link :to="{ name: 'listPolls' }">poll</router-link>
                  and
                  <router-link :to="{ name: 'listPetition' }"
                    >petition</router-link
                  >
                  platform where you can cast your vote on matters that are
                  affecting the community.
                </li>
                <!-- <li>A growing knowledge-base that you can tap into when you need to find answers to your relevant
                questions
              </li> -->
              </ul>

              <div class="bold-text">Wards within Emfuleni</div>
              <div>
                Join hands with us so that we can present a united front in our
                collective effort to restoring our communities to its former
                glory. We can't afford not to… Get in touch with us, because
                there are ample opportunities for minimizing cost and optimizing
                productivity.
                <router-link :to="{ name: 'ContactUs' }"
                  >Contact us</router-link
                >
                and let's have a chat.
              </div>

              <div class="bold-text mt-3">
                Ratepayers' Organizations and Associations in Other Municipal
                Areas
              </div>
              <div>
                Peruse this website and let us know if you would be interested
                in joining hands with us, so that we can draw the net nationally
                to benefit from multiple experiences across South Africa (and
                internationally, where relevant).
              </div>

              <div class="bold-text mt-3">
                Coalitions - ERPA has aligned with the following organizations,
                forming mutually beneficial relationships
              </div>
              <ul>
                <template v-for="col in coalitions">
                  <li>
                    {{ col.name }} (<a :href="col.link" target="_blank">{{
                      col.link
                    }}</a
                    >)
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              <h4>Support Needed</h4>
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
          >
            <div class="accordion-body">
              Before we can do anything to clean up our once flourishing
              community, we need a mandate from you, the ratepayer/resident of
              Emfuleni. Please go to
              <router-link :to="{ name: 'listPolls' }">polls</router-link> and
              <router-link :to="{ name: 'listPetition' }"
                >petitions</router-link
              >
              to see why we need your support, and cast your vote now.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              <h4>Map Coverage</h4>
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
          >
            <div class="accordion-body">
              <div>
                The Emfuleni Local Municipality (<a
                  href="https://municipalities.co.za/overview/1060/emfuleni-local-municipality"
                  target="_blank"
                  >GT421</a
                >) forms part of the greater Sedibeng District Municipality (<a
                  href="https://municipalities.co.za/overview/114/sedibeng-district-municipality"
                  target="_blank"
                  >DC42</a
                >) that includes the
                <a
                  href="https://municipalities.co.za/overview/1061/lesedi-local-municipality"
                  target="_blank"
                  >Lesedi</a
                >
                and
                <a
                  href="https://municipalities.co.za/overview/1062/midvaal-local-municipality"
                  target="_blank"
                  >Midvaal</a
                >
                Municipalities.
              </div>
              <img class="image" src="./GT42-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mt-5 row centered-content"
    v-if="store.state.isAuthenticated == false"
  >
    <div class="col-sm-12 col-md-6">
      <RegisterNow />
    </div>
  </div>
</template>

<style scoped>
.bold-text {
  font-weight: bold;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40%;
  max-height: 40%;
}
</style>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import RegisterNow from "./RegisterNow.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

let coalitions = ref(undefined);

onMounted(async () => {
  await getCoalitions();
});

async function getCoalitions() {
  const response = await store.dispatch("get", "news/coalitions/");
  if (response.status === 200) {
    coalitions.value = response.data;
  }
}
</script>
