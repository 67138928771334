<template>
    <div class="card">
      <div class="card-header">
        <h3 class="text-center fw-bold">Register</h3>
      </div>
      <div class="card-body">
        <p class="text-center">
          As an Emfuleni Ratepayer, one of the first contributions you can make,
          right now, is to register on this website.
        </p>
        <p class="text-center">
          We encourage every single Emfuleni Ratepayer to subscribe to this
          forum, as it will be the first platform where information will be
          shared.
        </p>
      </div>
      <div class="card-footer centered-content">
        <router-link
          :to="{ name: 'registration' }"
          type="button"
          class="btn btn-primary text-center"
          >Register</router-link
        >
      </div>
  </div>
</template>
