<template>
    <div class='col-auto'>
        <div class='card'>
            <div class='card-header'>
                <h4 style="text-align: center;">{{ campaign.date }}</h4>
                <h6 v-if="!campaign.presentation" style="text-align: center; cursor: pointer;"
                    @click="getPresentation(campaign)">Get Download Link</h6>
                <template v-if="campaign.presentation">
                    <a style="text-decoration: underline;" class="link-primary"
                        :href="campaign.presentation" :download="campaign.download">
                        Download Current Presentation
                    </a>
                </template>
            </div>
            <div class='card-body'>
                <h1 style="text-align: center;" class="text-primary">{{ campaign.attendance }}</h1>
                <h4 style="text-align: center;">{{ campaign.title }}</h4>
            </div>
            <div v-if="store.state.account && store.state.account.is_staff"
                class="card-footer centered-content">
                <router-link :to="{ name: 'updateCampaign', params: { slug: campaign.slug } }">Edit
                    Campaign
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'

let props = defineProps({
    campaign: {
        type: Object
    }
})

const store = useStore();

async function getPresentation(campaign) {
    const response = await store.dispatch('download', `campaigns/download/${campaign.slug}/`);
    if (response.status == 200) {
        let blob = response.data;
        campaign.presentation = window.URL.createObjectURL(blob);
    }
}
</script>