<template>
  <div v-if="news">
    <div id="article">
      <div class="centered-content">
        <h3>
          {{ news.title }}
          <router-link
            v-if="store.state.account && store.state.account.is_staff"
            class="ms-auto"
            :to="{ name: 'updateNews', params: { slug: slug } }"
          >
            <li class="material-icons text-success" style="cursor: pointer">
              create
            </li>
          </router-link>
        </h3>
        <h6>
          by {{ news.account }} | {{ $filters.dateTime(news.date_modified) }} |
          ERPA
        </h6>
        <!-- <img
          v-if="news.profile_image"
          style="max-width: fit-content; max-height: 250px;"
          :src="news.profile_image"
        /> -->
      </div>
      <hr />
      <div id="editor" v-html="readOnlyQuill(news.body)"></div>
    </div>
    <hr>

    <div
      id="comments"
      v-if="news.comments.length > 0"
      class="row centered-content"
    >
      <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
        <div class="card card-body mb-2">
          <h3 class="fw-bold">{{ news.comments.length }} Comment(s)</h3>
          <div v-for="comment in news.comments">
            <hr />
            <h6>
              <span class="fw-bold">{{ comment.full_name }}</span> on
              {{ $filters.date(comment.date_modified) }} at
              {{ $filters.time(comment.date_modified) }} wrote:
            </h6>
            <p>{{ comment.comment }}</p>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <h3 style="text-align: center">Be the first to leave a comment:</h3>
    </div>
    <form @submit.prevent="submitComment">
      <div class="row centered-content">
        <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6">
          <div class="card card-body w-auto">
            <div v-if="!store.state.account">
              <input
                id="full_name"
                type="text"
                class="form-control"
                v-model="comment.full_name"
                placeholder="Please provide Name"
              />
              <label
                v-if="errors && errors.full_name"
                v-for="error in errors.title"
                for="full_name"
                class="form-label text-danger"
              >
                {{ error }}
              </label>
            </div>
            <div class="mb-3">
              <textarea
                id="comment"
                type="text"
                class="form-control"
                v-model="comment.comment"
                rows="4"
                placeholder="Leave a comment"
              >
              </textarea>
              <label
                v-if="errors && errors.comment"
                v-for="error in errors.title"
                for="comment"
                class="form-label text-danger"
              >
                {{ error }}
              </label>
            </div>
            <button class="btn btn-primary ms-auto">Comment</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { readOnlyQuill } from "@/components/quillManager";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

let slug = route.params.slug || undefined;
let news = ref(undefined);
let comment = ref({
  news: slug,
  full_name: "",
  comment: "",
});
let errors = ref([]);

onMounted(async () => {
  await getNews();
});

async function getNews() {
  const response = await store.dispatch(
    "get",
    store.state.account && store.state.account.is_staff
      ? `news/${slug}/`
      : `news/public/${slug}/`
  );

  if (response.status == 200) {
    news.value = response.data;
  } else if (response.status == 404) {
    toast.error(`News article not found`, {
      timeout: 5000,
    });
    errors.value = response.data;
    router.push({ name: "listNews" });
  }
}

async function submitComment() {
  const payload = {
    api: `news/public/${slug}/`,
    data: comment.value,
  };
  const response = await store.dispatch("post", payload);

  if (response.status == 201) {
    toast.success(`Thank you for your comment`, {
      timeout: 5000,
    });

    comment.value.comment = "";

    await getNews();
  } else if (response.status == 404) {
    toast.error(`News article not found`, {
      timeout: 5000,
    });
    errors.value = response.data;
    router.push({ name: "listNews" });
  } else if (response.status == 400) {
    errors.value = response.data;
  }
}
</script>
